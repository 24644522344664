import { CChart } from "@coreui/react-chartjs";

const UserGraph = (props) => {
  return (
    <div>
      <h1 className="text-2xl ml-24 mb-8">Users by date</h1>
      <div className="w-2/3 m-auto">
        <CChart
          type="bar"
          data={{
            labels: props.dateLabels,
            datasets: [
              {
                label: "New Users",
                backgroundColor: "#f87979",
                data: props.amountEachDay,
              },
            ],
          }}
          labels="Days"
          options={{
            plugins: {
              legend: {
                labels: {
                  //color: getStyle('--cui-body-color'),
                },
              },
            },
            scales: {
              x: {
                grid: {
                  //color: getStyle('--cui-border-color-translucent'),
                },
                ticks: {
                  //color: getStyle('--cui-body-color'),
                },
              },
              y: {
                grid: {
                  //color: getStyle('--cui-border-color-translucent'),
                },
                ticks: {
                  //color: getStyle('--cui-body-color'),
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default UserGraph;
