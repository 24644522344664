const FormStats = (props) => {
    const {loading, formCount, userCount} = props

    return (<div><div className="grid grid-cols-2 mx-24 gap-8 mb-8">
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Submitted Forms:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{formCount}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Percent of Users Who Answered Forms:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{formCount / userCount * 100}%</h1>
          </div>
        </div>
      </div></div>)
}

export default FormStats