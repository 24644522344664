import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const UserCard = (props) => {
  const {user, setFocusedUser} = props;
  const signUpDate = new Date(user.createdAt)
  const memberUntil = new Date(user.membershipExpireDate)
  const now = new Date()
  return (
    <div className="border p-4">
      <div className="flex">
        <div className="self-center"><FontAwesomeIcon
          className="self-center w-16 h-16"
          icon={faUser}
        /></div>
        <div className="text-sm ml-2 self-center">
          <h1>Email: {user.email}</h1>
          <h1>First Name: {user.firstName}</h1>
          <h1>Last Name: {user.lastName}</h1>
          <h1>Signed up: {(signUpDate.getMonth() + 1) + '/' + signUpDate.getDate() + '/' +  signUpDate.getFullYear()}</h1>
          <h1>Membership: {now.getTime() <= memberUntil.getTime() ? "Yes" : "No"}</h1>
          <h1>Email Verified: {user.validatedEmail ? "Yes" : "No"}</h1>
        </div>
      </div>
        <div className="mt-4 text-md"><button onClick={() => setFocusedUser(user.email)} className="text-center bg-hover p-1 w-full">View Details</button></div>
    </div>
  );
};

export default UserCard;
