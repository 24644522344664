import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faArrowLeft,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import UserCard from "./userCard";
import { useEffect, useState } from "react";
import { getJWT } from "../authentication/jwt";

const UserLookup = (props) => {
  const { loading, populateData } = props;
  const [focusedUser, setFocusedUser] = useState("");
  const [user, setUser] = useState({});
  const [signUpDate, setSignUpDate] = useState(new Date());
  const [memberUntil, setMemberUntil] = useState(new Date());
  const [filteredUsers, setFilteredUsers] = useState(props.users);
  const [users, setUsers] = useState(props.users)
  const [resendMessage, setResendMessage] = useState("send")
  const [grantLoading, setGrantLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [editEmailLoading, setEditEmailLoading] = useState(false)

  const now = new Date();

  useEffect(() => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].email == focusedUser) {
        setUser(users[i]);
        setSignUpDate(new Date(users[i].createdAt));
        setMemberUntil(new Date(users[i].membershipExpireDate));
      }
    }
  }, [focusedUser, users]);

  useEffect(() => {setUsers(props.users)})

  const handleSearch = (e) => {
    let value = e.target.value;
    setFilteredUsers(users);
    setFilteredUsers((prev) =>
      prev.filter(
        (element) => element.email.substring(0, value.length) == value
      )
    );
  };

  const grantMembership = async () => {
    setGrantLoading(true)

    let numDays = document.getElementById("numDays").value;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + getJWT());

    const raw = JSON.stringify({
      email: focusedUser,
      numDays: numDays,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const request = await fetch(process.env.REACT_APP_API_URL + "/user/grantMembership", requestOptions);

    let res = await request.json()

    populateData()

    setGrantLoading(false)

  };

  const removeMembership = async () => 
  {
    setRemoveLoading(true)

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + getJWT());

    const raw = JSON.stringify({
      email: focusedUser,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const request = await fetch(process.env.REACT_APP_API_URL + "/user/removeMembership", requestOptions);

    let res = await request.json()

    populateData()

    setRemoveLoading(false)

  }

  const resendVerfication = async () => 
    {
      setResendMessage("loading")
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + getJWT());
  
      const raw = JSON.stringify({
        email: focusedUser,
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      const request = await fetch(process.env.REACT_APP_API_URL + "/user/resendVerfication", requestOptions);
  
      let res = await request.json()
  
      if(res.msg != "success")
      {
        setResendMessage(res.msg)
      }
      else
      {
        setResendMessage("Email sent")
      }
  
    }

    const editEmail = async () => 
      {
        setEditEmailLoading(true)

        let newEmail = document.getElementById("newEmail").value
    
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + getJWT());
    
        const raw = JSON.stringify({
          email: focusedUser,
          newEmail: newEmail
        });
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
        const request = await fetch(process.env.REACT_APP_API_URL + "/user/editEmail", requestOptions);
    
        let res = await request.json()
    
        populateData()
    
        setEditEmailLoading(false)
        setFocusedUser(newEmail)
      }

  if (!focusedUser)
    return (
      <div className="mx-24">
        <div className="flex">
          <FontAwesomeIcon
            className="absolute ml-2 self-center"
            icon={faMagnifyingGlass}
          />{" "}
          <input
            className="pl-7 self-center w-full border-2 rounded-xl border-black"
            type="text"
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <p className="text-sm">*Search by email</p>
        <div className="grid grid-cols-4 gap-4 mt-8">
          {filteredUsers.map((user) => (
            <UserCard user={user} setFocusedUser={setFocusedUser} />
          ))}
        </div>
      </div>
    );
  else
    return (
      <div className="mx-24">
        <button onClick={() => setFocusedUser("")}>
          <FontAwesomeIcon className="w-8 h-8" icon={faArrowLeft} />
        </button>
        <div className="flex mt-4">
          <div className="">
            <FontAwesomeIcon className="w-52 h-52 bg-hover p-8" icon={faUser} />
          </div>
          <div className="text-lg ml-8 self-center">
            <h1>Email: {user.email}</h1>
            <h1>First Name: {user.firstName}</h1>
            <h1>Last Name: {user.lastName}</h1>
            <h1>
              Signed up:{" "}
              {signUpDate.getMonth() +
                1 +
                "/" +
                signUpDate.getDate() +
                "/" +
                signUpDate.getFullYear()}
            </h1>
            <h1>
              Membership:{" "}
              {now.getTime() <= memberUntil.getTime() ? "Yes" : "No"}
            </h1>
            {now.getTime() <= memberUntil.getTime() ? (
              <h1>
                Member Until:{" "}
                {memberUntil.getMonth() +
                  1 +
                  "/" +
                  memberUntil.getDate() +
                  "/" +
                  memberUntil.getFullYear()}
              </h1>
            ) : (
              <></>
            )}
            <h1 >Email Verified: {user.validatedEmail ? "Yes" : "No"}</h1>
          </div>
        </div>
        <div className={now.getTime() <= memberUntil.getTime() ? "grid grid-cols-4 mt-4 gap-8" : "grid grid-cols-3 mt-4 gap-8"}>
          <div className="border p-8">
            <h1 className="text-center text-xl">Grant Membership</h1>
            <div className="flex mt-8">
              <div className="self-center text-nowrap	mr-4">
                <p>Number of Days:</p>
              </div>
              <input
                id="numDays"
                className="w-full p-1 border border-black"
                type="text"
              />
            </div>
            <div className="text-center mt-8">
              <button
                onClick={() => {
                  grantMembership();
                }}
                className="bg-black text-white py-1 px-2 rounded"
              >
                                {grantLoading ? <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg> : <span>Submit</span>}
              </button>
            </div>
          </div>
          {now.getTime() <= memberUntil.getTime() ? <div className="border p-8">
            <h1 className="text-center text-xl">Remove Membership</h1>
            <div className="text-center mt-24">
              <button
                onClick={() => {removeMembership()}}
                className="bg-black text-white py-1 px-2 rounded"
              >
                {removeLoading ? <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg> : <span>Submit</span>}
              </button>
            </div>
          </div> : <></>}
          <div className="border p-8">
            <h1 className="text-center text-xl">Resend Email Verification</h1>
            <div className="text-center mt-24">
              <button
                onClick={() => {resendVerfication()}}
                className="bg-black text-white py-1 px-2 rounded"
              >
                {resendMessage == "loading" ? <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg> : <span>{resendMessage}</span>}
              </button>
            </div>
          </div>
          <div className="border p-8">
            <h1 className="text-center text-xl">Edit Email</h1>
            <div className="flex mt-8">
              <div className="self-center text-nowrap	mr-4">
                <p>New Email:</p>
              </div>
              <input id="newEmail" className="w-full p-1 border border-black" type="text" />
            </div>
            <div className="text-center mt-8">
              <button
                onClick={() => {editEmail()}}
                className="bg-black text-white py-1 px-2 rounded"
              >
                {editEmailLoading ? <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg> : <span>Submit</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default UserLookup;
