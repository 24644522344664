import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faClipboard,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import FormCard from "./formCard";
import { elements } from "chart.js";

const FormLookup = (props) => {
  const { forms, users } = props;
  const [filteredForms, setFilteredForms] = useState(forms);
  const [focusedForm, setFocusedForm] = useState("");
  const [form, setForm] = useState({});
  const [user, setUser] = useState({});

  const handleSearch = (e) => {
    let value = e.target.value;
    setFilteredForms(forms);
    setFilteredForms((prev) =>
      prev.filter(
        (element) => element.email.substring(0, value.length) == value
      )
    );
  };

  useEffect(() => {
    if(focusedForm)
    {
    setForm(forms.find((element) => element.email == focusedForm));
    setUser(users.find((element) => element.email == focusedForm));
    }
  }, [focusedForm]);

  if (!focusedForm)
    return (
      <div className="mx-24">
        <div className="flex">
          <FontAwesomeIcon
            className="absolute ml-2 self-center"
            icon={faMagnifyingGlass}
          />{" "}
          <input
            className="pl-7 self-center w-full border-2 rounded-xl border-black"
            type="text"
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <p className="text-sm">*Search by email</p>
        <div className="grid grid-cols-4 gap-4 mt-8">
          {filteredForms.map((form) => (
            <FormCard
              form={form}
              user={users.find((element) => element.email == form.email)}
              setFocusedForm={setFocusedForm}
            />
          ))}
        </div>
      </div>
    );
  else
    return (
      <div className="mx-24">
        <button onClick={() => setFocusedForm("")}>
          <FontAwesomeIcon className="w-8 h-8" icon={faArrowLeft} />
        </button>
        <div className="flex mt-4">
          <div className="">
            <FontAwesomeIcon
              className="w-52 h-52 bg-hover p-8"
              icon={faClipboard}
            />
          </div>
          <div className="text-lg ml-8 self-center">
            <h1 className="text-nowrap	">Email: {user.email}</h1>
            <h1>First Name: {user.firstName}</h1>
            <h1>Last Name: {user.lastName}</h1>
            <h1>Age: {form.age == -1 ? "Unanswered" : form.age}</h1>
            <h1>Location: {form.location}</h1>
            <h1>Interest: {form.interest && form.interest.join(", ")}</h1>
          </div>
          <div className="text-end w-full">{Date(form.createdAt)}</div>
        </div>
        <div className="border mt-8 p-8 h-80">
            <div>
            <h1 className="text-center text-xl">Additional Comments:</h1>
            <p className="text-lg text-center">{form.additionalComments}</p>
            </div>
        </div>
      </div>
    );
};

export default FormLookup;
