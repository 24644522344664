import { useState } from "react";
import { setJWT } from "../authentication/jwt";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const [err, setErr] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    let email = document.getElementById("email").value;
    let password = document.getElementById("password").value;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(process.env.REACT_APP_API_URL)

    let results = await fetch(process.env.REACT_APP_API_URL + "/user/login", requestOptions)
    let response = await results.json()

    if(response.msg == "Success")
    {
      if(response.user.userRole == 1)
      {
        setJWT(response.accessToken)
        navigate("/dashboard")
      }
      else
      {
        setErr("Not a moderator")
      }
    }
    else
    {
      setErr(response.msg)
    }
  
  };

  return (
    <div>
      <h1 className="text-center mt-4 mb-4">
        Welcome to the Wealth Adventures admin portal!
      </h1>
      <div className="border w-1/2 m-auto">
        <form className="m-8" onSubmit={handleLogin}>
          <p>email</p>
          <input type="text" id="email" className="w-full border mt-1 mb-4" />
          <p>Password</p>
          <input type="text" id="password" className="w-full border mt-1" />
          <input
            className="text-center w-full mt-8 cursor-pointer"
            onClick={handleLogin}
            type="submit"
          />
        </form>
        {err ? <div className=" mb-4 text-center text-red">error: {err}</div> : <div></div>}
      </div>
    </div>
  );
};

export default Login;
