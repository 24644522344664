import { useState } from "react";

const UserStats = (props) => {
    const {loading, userCount, verifiedUserCount, totalMembers} = props

    return (<div><div className="grid grid-cols-3 mx-24 gap-8 mb-8">
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">User Count:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{userCount}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Verified User Count:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{verifiedUserCount}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Premium Members:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{totalMembers}</h1>
          </div>
        </div>
      </div></div>)
}

export default UserStats