import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";

const FormCard = (props) => {
  const { form, user, setFocusedForm } = props;


  return (
    <div>
      <div className="border p-4">
        <div className="flex">
          <div className="self-center">
            <FontAwesomeIcon className="self-center w-16 h-16" icon={faClipboard} />
          </div>
          <div className="text-sm ml-2 self-center">
            <h1>Email: {form.email}</h1>
            <h1>First Name: {user.firstName}</h1>
            <h1>Last Name: {user.lastName}</h1>
            <h1>Age: {form.age == -1 ? "Unanswered" : form.age}</h1>
            <h1>Location: {form.location}</h1>
            <h1>Interest: {form.interest.join(", ")}</h1>
          </div>
        </div>
        <div className="mt-4 text-md">
          <button
            onClick={() => {setFocusedForm(user.email)}}
            className="text-center bg-hover p-1 w-full"
          >
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormCard;
