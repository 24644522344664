import { useState } from "react";

const Overview = (props) => {
  const {loading, userCount, verifiedUserCount, totalMembers, revenue, articlesRead, formsAnswered, quizzesTaken} = props
  //Stats states

  return (
    <div>
      <div className="grid grid-cols-3 mx-24 gap-8 mb-8">
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">User Count:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{userCount}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Verified User Count:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{verifiedUserCount}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Premium Members:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{totalMembers}</h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 mx-24 gap-8 mb-8">
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Total Revenue:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>${revenue}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Articles Read:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{articlesRead}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Forms Answered:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{formsAnswered}</h1>
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="m-8">
            <h1 className="text-2xl">Quizzes Taken:</h1>
            <h1 className={loading ? "animate-pulse text-5xl bg-hover text-hover w-24" : " text-5xl"}>{quizzesTaken}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
